import React from "react";
import { useLocation } from 'react-router-dom'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import DpoImage from '../../assets/dpoImage.jpg';



function DataPrivacy() {

    const [readMoreModalOpen, setReadMoreModalOpen] = React.useState(false);
    const location = useLocation();

    React.useEffect(() => {
    }, [location.pathname]);

    const handleClose = () => {
        setReadMoreModalOpen(false);
    }

    const openReadMoreModal = () => {
        setReadMoreModalOpen(true);
    }

    return (
        <>
            <Header />
            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, flexDirection: 'column' }}>

                <Box sx={{ textAlign: 'center', padding: '30px' }} id="profile">
                    <Typography variant="h5" sx={{ color: '#000', fontWeight: 'bold' }}>NPC Seal</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={DpoImage} style={{ width: '200px' }} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px', padding: '30px' }}>
                    <Box sx={{ textAlign: 'justify' }}>
                        <Typography variant="h6" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '50px' }}>
                            The NPC is committed to ensuring strict compliance with these requirements and will not hesitate to enforce penalties and fines on organizations found in violation. We emphasize the crucial importance of adhering to these guidelines for the protection of individuals privacy rights. Failure to comply with this mandate will result in the following sanctions and penalties:
                        </Typography>
                        <ul>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '20px' }}>
                                1. Revocation of the Certificate of Registration under Section 35(A) of NPC Circular No. 2022-04 (Registration of Personal Data Processing System. Notification Regarding Automated Decision-making or Profiling. Designation of Data Protection Officer, and the Nation Privacy Commission Seal of Registration) for non-compliance with NPC's directives.
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '20px' }}>
                                2. Liability to pay Administrative Fines for non-compliance under Section 37 of NPC Circular No. 2022-04. as well as NPC Circular No. 2022-01 (Guidelines on Administrative Fines) for other infractions: and/or
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '20px' }}>
                                3. Issuance of a Cease and Desist Order from processing personal data under Section 38 of NPC Circular No. 2022-04.
                            </Typography>
                        </ul>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, flexDirection: 'column' }}>
                <Box sx={{ textAlign: 'center', padding: '30px' }} id="profile">
                    <Typography variant="h5" sx={{ color: '#000', fontWeight: 'bold' }}>NPC Seal</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                    <Box sx={{ flex: 1, display: 'flex' }}>
                        <Box sx={{ width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={DpoImage} style={{ width: '300px' }} />
                        </Box>
                        <Box sx={{ width: '40%', paddingRight: '35px', textAlign: 'justify' }}>
                            <Typography variant="h6" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '50px' }}>
                                The NPC is committed to ensuring strict compliance with these requirements and will not hesitate to enforce penalties and fines on organizations found in violation. We emphasize the crucial importance of adhering to these guidelines for the protection of individuals privacy rights. Failure to comply with this mandate will result in the following sanctions and penalties:
                            </Typography>
                            <ul>
                                <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '20px' }}>
                                    1. Revocation of the Certificate of Registration under Section 35(A) of NPC Circular No. 2022-04 (Registration of Personal Data Processing System. Notification Regarding Automated Decision-making or Profiling. Designation of Data Protection Officer, and the Nation Privacy Commission Seal of Registration) for non-compliance with NPC's directives.
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '20px' }}>
                                    2. Liability to pay Administrative Fines for non-compliance under Section 37 of NPC Circular No. 2022-04. as well as NPC Circular No. 2022-01 (Guidelines on Administrative Fines) for other infractions: and/or
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom sx={{ color: '#000', fontWeight: 'normal', marginBottom: '20px' }}>
                                    3. Issuance of a Cease and Desist Order from processing personal data under Section 38 of NPC Circular No. 2022-04.
                                </Typography>
                            </ul>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Footer />

        </>
    )
}

export default DataPrivacy;